import React, {useState, useEffect, useRef} from 'react';
import './notifications.scss';
import {useDispatch, useSelector} from "react-redux";
import {getNotificationAPI, getNotificationsAPI} from "../../api";
import {loadNotifications, updateNotification} from "../../reducers/notifications";
import {Dropdown, Badge} from "react-bootstrap";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        type={'button'}
        className='btn btn-link p-0'
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </button>
));

const Notifications = () => {
    const {notifications=[]} = useSelector(state=>state);
    const node = useRef();
    const dispatch = useDispatch();
    const count = notifications.filter(f=>f.unread===true).length;

    const checkForNewNotifications = async () =>{
        try{
            let {error,data} = await getNotificationsAPI();
            if(!error){
                dispatch(loadNotifications(data.filter(f=>f.type!=='payment')));
            }
        }catch (err){

        }
    }

    const setRead = async _id =>{
        try{
            const {error} = await getNotificationAPI(_id);
            if(!error){
                dispatch(updateNotification({_id,unread:false}))
            }else {
                //console.log(error)
            }
        }catch (err){
            // console.log(err.message);
        }
    }

    useEffect(()=>{
        let interval = setInterval(checkForNewNotifications,3000);

        return ()=>{
            clearInterval(interval)
        }
    },[])

    return(

            <div className='notifications d-none d-md-inline-flex' ref={node}>
                <Dropdown className='d-flex'>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        <i className='fal fa-bell'></i>
                        <Badge bg={`danger`} className={`${count > 0 ? '' : ' d-none'}`}>{count}</Badge>
                    </Dropdown.Toggle>

                    <Dropdown.Menu >
                        <div className="dropdown-header bg-light"><strong>You have {count} notifications</strong></div>
                        {notifications.sort((a,b)=>b.timestamp-a.timestamp).map(({_id, text, unread, type}) => {

                            const ico = type !== 'payment' ? 'fal fa-sticky-note text-secondary' : 'fad fa-coins';
                            return (
                                <button
                                    key={_id}
                                    type='button'
                                    className="btn-link dropdown-item"
                                    onClick={e => setRead(_id)}
                                >
                                    {unread === true
                                        ? <i className='badge badge-info'>New</i>
                                        : <i className={ico}></i>
                                    }

                                    <span className={`${unread === true ? 'font-weight-bold' : ''}`}>{text}</span>
                                </button>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>,
            </div>

    )
}




export default Notifications;
