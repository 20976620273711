import React from 'react';
import {Slide, ToastContainer} from "react-toastify";
import SignIn from "../components/Auth/sign-in";
import Helmet from 'react-helmet'
import {APP_DESCRIPTION, APP_TITLE} from "../constants/settings";

const SignInPage = () => {
    return (
        <>
            <Helmet>
                <meta name="title" content={APP_TITLE} />
                <meta name="description" content={APP_DESCRIPTION} />
                <title>{`${APP_TITLE}`}</title>
            </Helmet>

            <SignIn/>
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                transition={Slide}
            />
        </>
    );
};

export default SignInPage;
