const account =  (state= {}, action) => {
    switch (action.type) {
        case "LOAD_ACCOUNT":
            return {
                ...action.data
            };
        default :
            return state
    }
}
export default account;

export const loadAccount = data =>({type:'LOAD_ACCOUNT', data})