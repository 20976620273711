import React from 'react';
import {useLocation, useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";
import "./language-menu.scss";
import language_img from "./languages.svg"
function LanguageMenu({ns='translate'}) {
    const {  language,   } = useParams();
    const { pathname, } = useLocation()
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(ns)
    function changeLanguage(lan) {
        let pathArray = pathname.split('/');
        if(pathArray.length===0){
            return
        }
        if(pathArray[0].length===0){
            pathArray.shift();
        }
        if(pathArray[0].length===2){
            pathArray[0]=lan;
        }else{
            pathArray.unshift(lan);
        }
        pathArray.unshift('')
        const path = pathArray.join('/');
        navigate(path);
        i18n.changeLanguage(lan);
    }
    return (
        <div className="enter-languages fs-12 fs-xxl-12 text-center mt-auto">
            <div className="for-tit">
                <div className="icon mb-2">
                    <img src={language_img} alt="languages"/>
                </div>
                <p>Choose language:</p>
            </div>
            <div className="links mt-3">
                <a className={` ${language==='ru'?'active':''}`} onClick={e=>changeLanguage('ru')}>Русский</a>
                <a onClick={e=>changeLanguage('en')}>English</a>
                <a   onClick={e=>changeLanguage('cn')}>中国人</a>
            </div>
        </div>

    );
}

export default LanguageMenu;