import { combineReducers } from 'redux'
import modems from "./modems";
import settings from "./settings";
import notifications from "./notifications";
import account from "./account";
import invoices from "./invoices";



export const rootReducer = combineReducers({
  modems,
  settings,
  notifications,
  account,
  invoices,
})
