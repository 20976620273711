import {logout} from "../api";
import RenderInRoot from "../components/Helpers/RenderInRoot";
import React, {useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Notifications from "../components/Notifications";

const TemplateHeader = props =>{
    const { t, i18n } = useTranslation('container');
    const onLogout = () =>{
        logout().finally(r=>{
            window.location="/account"
        });

    }

    return(
        <>
            <div className="mobile-header">
                <a className="logo" href="#">
                    <img className="mw-100" src="/assets/images/logo-white.svg" alt="logo"/>
                </a>
                <div className="humb">
                    <div className="dv-1"></div>
                    <div className="dv-2"></div>
                    <div className="dv-3"></div>
                </div>
            </div>
            <header className="dashboard-header">
                <a className="logo text-center border-end bd-gray" href="">
                    <img className="mw-100" src="/assets/images/logo.svg" alt="logo"/>
                </a>
                <div className="header-blocks" >

                    <div id={'left_top_menu'} className="d-flex" style={{maxWidth: "calc(100% - 70px)"}}></div>

                    <div className="h-blocks h-right">

                        <Notifications/>
                    </div>
                </div>
            </header>
        </>

    )
}
export default TemplateHeader