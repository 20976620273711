import React, {Suspense, lazy, useState, useEffect} from "react";
import CustomerBootstrapTemplate from "../containers";

import Spinner from "../components/Spinner";
import {Navigate, Routes} from "react-router";
import {Route, useLocation, useParams} from "react-router-dom";
import {useAuthUser} from "../utils/AuthUser";
import {useDispatch} from "react-redux";
import {loadAccount} from "../reducers/account";
import {getAccountAPI} from "../api";

const ModemsPage = lazy(()=>import("../pages/modems"));
const ModemPage = lazy(()=>import("../pages/modem"));
const ServersPage = lazy(()=>import("../pages/servers"));
const AccountPage = lazy(()=>import("../pages/account"));
const InvoicePage = lazy(()=>import("../pages/invoices"));
const BillingsPage = lazy(()=>import("../pages/billings-page"));

const AuthorizedRoutes = props =>{
    const { logged, setReturnUrl,  setLogged, checkAuth } = useAuthUser();
    const {  language,   } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        checkAuth()
            .catch(err => {
                setLogged(false);
                if (location.pathname.includes('sign-in')) {
                    return;
                }

                setReturnUrl(location?.pathname);

                let path = language && language.length === 2 ? `/${language}/sign-in` : `/en/sign-in`;
                window.location = path
            })
    }, [location.pathname]);

    useEffect(()=>{
        getAccountAPI().then(r => dispatch(loadAccount(r)) );
    },[])
    return(
            <Suspense fallback={<Spinner />}>
                <Routes>
                    <Route path={'/'} element={<CustomerBootstrapTemplate/>}>
                        <Route path={'/:language/modems'} element={<ModemsPage/>}/>
                        <Route path={'/:language/modems/:modem_id'} element={<ModemPage/>}/>
                        <Route path={'/:language/servers'} element={<ServersPage/>}/>
                        <Route path={'/:language/account'} element={<AccountPage/>}/>
                        <Route path={'/:language/invoices'} element={<InvoicePage/>}/>
                        <Route path={'/:language/billings'} element={<BillingsPage/>}/>

                        <Route
                            path="*"
                            element={<Navigate to={'/en/account'}/>}
                        />
                    </Route>
                </Routes>
            </Suspense>
    )
}
export default AuthorizedRoutes;