//import "./auth-old.scss"
import {Link, useParams} from "react-router-dom";
import React, {useState} from "react";
import LanguageMenu from "./language-menu";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import emailVerifiedImg from "./email-verified.png"
import LeftSideWithDescription from "./left-side-with-description";
const EmailVerified = props =>{
    const {  language, email   } = useParams();
    const { t } = useTranslation('auth')
    const navigate = useNavigate();
    return(
        <div className="enter-inner log-in-inner">
            <LeftSideWithDescription t={t}/>
            <div className="enter-main">
                <div className="forma">
                    <div className='for-title mb-3'>
                        <div className='icon'>
                            <img src={emailVerifiedImg} style={{height:65,marginBottom:20}}/>
                        </div>

                        <div className="title text-uppercase" style={{fontSize:24}}>{t('Email_verified')}</div>
                    </div>

                    <form >
                        <div className="inputblock mb-3">
                            <label htmlFor="name" className='text-center'>{t("Great")}</label>
                            <input className="form-control" placeholder="name@coronium.io" name="login" id="login" type="email" defaultValue={email} readOnly={true} disabled={true}
                                   required/>
                        </div>

                        <div className="order mt-4">
                            <button className="send cl-btn" onClick={()=>{
                                navigate(`/${language}`);
                            }} >
                                {t('Continue_to')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default EmailVerified;