import React, {useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {useAuthUser} from "../../utils/AuthUser";
import {checkCredentials, sendConfirmationEmailLinkAPI} from "../../api";
import {Trans, useTranslation} from "react-i18next";
import LanguageMenu from "./language-menu";
import {toast} from "react-toastify";
import LeftSideWithDescription from "./left-side-with-description";
import emailImg from "./email-verification.png"
const ConfirmEmail = () => {
    const { setLogged,  returnUrl } = useAuthUser();
    const [error, setError] = useState();
    const form = useRef();
    const {  language, email   } = useParams();
    const { t } = useTranslation('auth')
    const redirect = returnUrl&&returnUrl.length>2?returnUrl:'/';

    const onSubmit = event =>{
        event.preventDefault();

      //  const login = form.current.elements["login"].value;
        setError(undefined);
        sendConfirmationEmailLinkAPI({login:email,language})
            .then(d=>{
                if(d.error){
                    toast.error(error)
                }else{
                    toast.success('Link was sent to email');

                    //form.current.submit();
                   // setLogged(true);
                }
            })
            .catch(err=>setError(err.message));
    }
    return (
        <>
            <div className="enter-inner  verification-inner log-in-inner flex-row p-0">
                <LeftSideWithDescription t={t}/>
                <div className="enter-main enter-content ">
                    <div className="forma">
                        <div className="for-title mb-3">
                            <div className="icon">
                                <img src={emailImg} alt="email" height={51}/>
                            </div>
                            <div className="title text-uppercase">{t('Confirm_your_email')}</div>
                            <p>{t("Before_accessing")}</p>
                        </div>
                        <form ref={form} onSubmit={onSubmit}  action='/signin' method='POST'>
                            <input type={'hidden'} name={'redirect'} value={redirect}/>
                            <div className="inputblock mb-3">
                                <input className="form-control" placeholder="name@coronium.io" name="login" id="login" type="email" defaultValue={email} readOnly={true} disabled={true}
                                       required/>
                            </div>

                            {error&&<p className='text-danger'>{error}</p>}
                            <div className="order mt-4">
                                <button className="send cl-btn dark-btn" type="submit">
                                    {t('Send_confirmation_email')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    );
};

export default ConfirmEmail;

