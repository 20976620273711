import React, {useEffect} from 'react';
import {Slide, ToastContainer} from "react-toastify";
import NewPasswordForm from "../components/Auth/new-password-form";
import Helmet from "react-helmet";
import {APP_DESCRIPTION, APP_TITLE} from "../constants/settings";
import {useParams} from "react-router-dom";

const NewPasswordPage = (props) => {
    const {  token,   } = useParams();
    return (
        <>
            <Helmet>
                <meta name="title" content={APP_TITLE} />
                <meta name="description" content={APP_DESCRIPTION} />
                <title>{`${APP_TITLE}`}</title>
            </Helmet>
            <NewPasswordForm token={token}/>
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                transition={Slide}
            />
        </>
    );
};

export default NewPasswordPage;
