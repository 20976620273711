//import "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.min.css';
import { Provider } from 'react-redux';
import configureStore from "./store/configureStore";
import Preloader from "./components/Preloader";
import Routes from "./routes";
import {AuthUserProvider} from "./utils/AuthUser";
import "./i18n"

const store = configureStore()
//https://react.i18next.com/getting-started
Object.defineProperty(String.prototype, 'capitalize', {
    value: function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
});
function App(props) {

    return (
        <Provider store={store}>
            <Preloader>
                <AuthUserProvider store={store}>
               {/*     <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY}>*/}
                        <Routes {...props} />
                   {/* </GoogleReCaptchaProvider>*/}
                </AuthUserProvider>
            </Preloader>
        </Provider>
    );
}


export default App;
