import React from 'react';
import LanguageMenu from "./language-menu";
import {Trans} from "react-i18next";
import logo from "./logo.png"
function LeftSideWithDescription({t}) {
    return (
        <div className="enter-info">
            <div className="logo">
                <a href="">
                    <img className="mw-100" src={logo} alt="logo"/>
                </a>
            </div>

            <div className="for-text text-left text-md-left" >
                <Trans i18nKey="welcome" ns="auth">
                    <div className="tit">
                        Register to our <br/> dashboard and enjoy:
                    </div>
                    <div className="text">
                        <p>Automated billing</p>
                        <ul>
                            <li>Pay using Bitcoin or Credit Card</li>
                            <li>Subscribe or do one time payment</li>
                            <li>Renew anytime you want by adding custom days</li>
                        </ul>
                    </div>
                    <div className="text">
                        <ul>
                            <li>Proxy management tools</li>
                            <ul>
                                <li>API</li>
                                <li>Interval rotations</li>
                                <li>IP Whitelisting</li>
                            </ul>
                        </ul>
                    </div>
                </Trans>
            </div>
            <LanguageMenu ns={'auth'}/>
            <div className="link exit-link">
                <a href="https://coronium.io">← {t("Exit_dashboard")}</a>
            </div>
        </div>
    );
}

export default LeftSideWithDescription;