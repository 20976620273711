import {useAuthUser} from "../utils/AuthUser";
import AuthorizedRoutes from "./authorized-routes";
import UnauthorizedRoutes from "./unauthorized-routes";

const Routes = props=>{
    const {logged} = useAuthUser();

    if(logged===undefined){
        return <div>loading.....</div>
    }
    if(logged){
        return <AuthorizedRoutes/>
    }else {
        return <UnauthorizedRoutes/>
    }
}

export default Routes;