import "./auth-old.scss"
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {changePasswordAPI} from "../../api";
import {useNavigate} from "react-router";

const NewPasswordForm =  props =>{
    const {token} = props;
    const navigate = useNavigate();
    const [error, setError] = useState();
    const formSubmit = async event =>{
        event.preventDefault();
        const form = event.currentTarget;
        const password = form.elements["password"].value;
        const repassword = form.elements["repassword"].value;
        if(password!==repassword){
            return setError('Password not equal');
        }
        try{
            let d = await changePasswordAPI({password,token});
            if(d.error){
                return setError(d.error);
            }
            navigate('/sign-in');
        }catch (err){
            setError(err.message);
        }
    }
    return(
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="wrapper">
                <header className="auth-header">
                    <Link to={'/'} className="logo">
                        <img src="/assets/images/logo_dark.svg" alt="Coronium"/>
                    </Link>
                </header>
                <main className="auth-main">
                    <div className="title t-res">Enter new password</div>

                    <form action="/" className="auth-form" onSubmit={formSubmit}>
                        <div className="inp-block mb-3">
                            <label htmlFor="password">Your new password</label>
                            <input type="password" name="password" id="password" placeholder="name@coronium.io"/>
                        </div>
                        <div className="inp-block mb-3">
                            <label htmlFor="repassword">Repeat new password</label>
                            <input type="password" name="repassword" id="repassword" placeholder="name@coronium.io"/>
                        </div>
                        {error&&<p className='text-danger'>{error}</p>}
                        <div className="controls">
                            <button type="submit" className="cl-btn w-100">Change password</button>
                        </div>
                    </form>
                </main>
            </div>
        </div>
    )
}
export default NewPasswordForm;