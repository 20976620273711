const invoices =  (state=[], action) => {
    switch (action.type) {

        case "LOAD_INVOICES":
            return [
                ...action.data
            ];
        case "ADD_INVOICE":
        case "UPDATE_INVOICE":
            let duplicate = state.some(row=>(row._id===action.data._id));
            if(!duplicate){
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_INVOICE" :
            return state.filter(
                c => c._id !== action.data._id
            )

        default :
            return state
    }
}
export default invoices;

export const loadInvoices = data =>({type:'LOAD_INVOICES', data})
export const addInvoice = data =>({type:'ADD_INVOICE', data})
export const updateInvoice = data =>({type:'UPDATE_INVOICE', data})
export const deleteInvoice = _id =>({type:'DELETE_INVOICE', data:{_id}});
