
const notifications =  (state=[], action) => {
    switch (action.type) {
        case "LOAD_NOTIFICATIONS":
            return [
                ...action.data
            ];
        case "ADD_NOTIFICATION":
        case "UPDATE_NOTIFICATION":
            let duplicate = state.some(row=>(row._id===action.data._id));
            if(!duplicate){
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_NOTIFICATION" :
            return state.filter(
                c => c._id !== action.data._id
            )

        default :
            return state
    }
}
export default notifications;

export const loadNotifications = data =>({type:'LOAD_NOTIFICATIONS', data})
export const addNotification = data =>({type:'ADD_NOTIFICATION', data})
export const updateNotification = data =>({type:'UPDATE_NOTIFICATION', data})
export const deleteNotification = _id =>({type:'DELETE_NOTIFICATION', data:{_id}});
