import React, {createRef, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {checkLoginAPI, signUpAPI} from "../../api";
import logo from "./logo.svg";
import passwordValidate from "./password-validate";
import {Trans, useTranslation} from "react-i18next";
import LanguageMenu from "./language-menu";
import LeftSideWithDescription from "./left-side-with-description";
function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //const re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    return re.test(String(email).toLowerCase());
}
function validatePurpose(purpose){
    if(!purpose||purpose.length<10){
        return "Purpose must be at least 10 characters long"
    }else {
        return undefined;
    }
}
const SignUp = props => {
    const [error, setError] = useState();
    const {  language='en',   } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation('auth')
    const handleSubmit = async (event)=> {
        event.preventDefault();

        const form = event.currentTarget;
        const name = form.elements["firstName"].value;
        const company_name = form.elements["companyName"].value;
        const purpose = form.elements["purpose"].value;

        const login = form.elements["login"].value;
        const password = form.elements["password"].value;
        const repassword = form.elements["repassword"].value;
        const passwordMustContainsSymbols = passwordValidate(password);
        const purposeValidateLength = validatePurpose(purpose)

        if(passwordMustContainsSymbols){
            return setError(passwordMustContainsSymbols);
        }
        if(purposeValidateLength){
            return setError(purposeValidateLength);
        }

        if(!validateEmail(login)){
            return setError('Login is not email address!');
        }
        if(password!==repassword){
            return setError('Password not equal');
        }


        setTimeout(()=>setError(undefined),2000);

        try{
            let d = await checkLoginAPI({login, name});
            if(d.error){
                return setError(d.error);
            }
            d= await signUpAPI({login, password, name, company_name, purpose, language});
            if(d.error){
                return setError(d.error);
            }
            navigate(`/${language}/confirm-email/${login}`);
        }catch (err){
            setError(err.message);
        }
    }

    return (
        <>
            <div className="enter-inner sign-inner">
               <LeftSideWithDescription t={t}/>
                <div className="enter-main">
                    <div className="forma">
                        <div className="title">{t('Signup')}</div>
                        <form method='POST' action='/signup' onSubmit={handleSubmit}>
                            <div className="inputblock">
                                <label htmlFor="name">{t('Your_name_and_surname')}</label>
                                <input className="form-control" id="name" placeholder="Jonas Curry" name="firstName"
                                       id="firstName"
                                       type="text" required/>
                            </div>
                            <div className="inputblock">
                                <label htmlFor="company">{t('company_name')}</label>
                                <input className="form-control" id="companyName" placeholder="Coronium.io"
                                       name="companyName"
                                       type="text"/>
                            </div>
                            <div className="inputblock mb-3">
                                <label htmlFor="name">{t('Your_email')}</label>
                                <input className="form-control" placeholder="name@coronium.io" name="login" id="login"
                                       type="email"
                                       required/>
                            </div>
                            <div className="inputblock">
                                <label htmlFor="name">{t("Password")}</label>
                                <input className="form-control" placeholder={t("password_must_includes")}
                                       name="password" id="password" type="password" required/>
                            </div>
                            <div className="inputblock mb-3">
                                <label htmlFor="name">{t('Repeat_Password')}</label>
                                <input className="form-control" placeholder={t("password_must_includes")}
                                       name="repassword" id="repassword" type="password" required/>
                            </div>

                            <div className="inputblock">
                                <label htmlFor="purpose">{t('What_purpose')}?</label>
                                <textarea className="form-control" name="purpose" id="purpose"
                                          placeholder={t('Purpose_for_proxies')}></textarea>
                            </div>

                            <div className="order">
                                <button className="send cl-btn" type="submit">
                                    {t('Create_account')}
                                </button>
                            </div>
                            {error && <p className='text-danger mt-2 '>{error}</p>}
                        </form>
                        <Trans i18nKey="policy" ns="auth">
                            <div className="link mb-3 pb-3 border-bottom bd-gray">
                                By clicking the “Create account” button you agree to our <a className="color-purple"
                                                                                            target="_blank"
                                                                                            href="https://coronium.io/terms-of-service/">Terms
                                of Service</a> and <a className="color-purple" target="_blank"
                                                      href="https://coronium.io/refund-policy/">Refund Policy</a>
                            </div>
                        </Trans>
                        <div className="link login-link">
                            {t("Already_have_an_account")}? <Link
                            to={`/${language || 'en'}/sign-in`}>{t('Login')}</Link>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
};

export default SignUp;
