import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import {Routes, Navigate, useLocation} from "react-router";
function CustomRoute({children}){
  //  const {pathname, search, hash} = useLocation();
    return children
   /* return  pathname !== '/' && pathname.slice(-1) === '/' ? (
        <Navigate to={`${pathname.slice(0, -1)}${search}${hash}`}/>
    ) : (
        children
    )*/
}
// Normalize all paths to not have trailing slashes even if they
// matched <Route> with one:
const Router = ({children}) => {
   // const {pathname, search, hash} = useLocation();
   // {(pathname !== '/' && pathname.slice(-1) === '/')&& <Navigate to={`${pathname.slice(0, -1)}${search}${hash}`}/>}

    return(

        <BrowserRouter>
            {children}
            {/*<Routes>
                <Route element={<CustomRoute children={children}/>}/>
            </Routes>*/}
        </BrowserRouter>
    )
}

export default Router
