import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
const languages = ['en', 'ch', 'ru']
i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection:{
            // order and from where user language should be detected
            order: ['path'],
            lookupFromPathIndex: 0,
        },
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
       /* resources: {
            en: {
                translation: {
                    "English": "English",
                    "Russian": "Russian"
                }
            },
            ru: {
                translation: {
                    "English": "Английский",
                    "Russian": "Русский",
                    "Your Proxies": "Ваши Прокси",
                    "Add New Proxies": "Добавить новую прокси",
                    "Your Account": "Ваш Аккаунт",
                    "Billing & Renewals": "Обновление подписки",

                }
            }
        },*/
       // lng: "en", // if you're using a language detector, do not define the lng option
        fallbackLng: "en",
        debug: false,
  //      whitelist: languages,
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.js',
            allowMultiLoading: true,
            parse: function(data) {
                try{
                    let a = data.replace(/(\r|\n|\{|\})/g, '').replace(/`/g,'');
                    const variables = a.split(';');
                    let res = {}
                    for(let variable of variables){
                        let [key, value] = variable.split('=');
                        //key=key.replace(/(\:|\s)+/g,'');
                        key=key.replace(/(\:|const)+/g,'').trim();
                        if(key&&value){
                            res[key]=value
                        }
                    }
              //      console.log(res)
                    return res;
                }catch (err){
                    //console.log(err.message);
                }
            /*  //  let dd = JSON.parse(data);
                console.log(data)
                let d =  JSON.stringify(data.replace(/(\r|\n)/g, ''));d=d.replace(/`/g,'"'); console.log(d); return d*/
            },
        },

        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        react:{
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i']
        }
    });
i18n.on('languageChanged', function(lng) {
   // console.log({lng})
})