import React, {useEffect} from "react";
import  "../styles/modal-payment.scss";
import "./scss/template.scss"
import "./scss/style.scss"
import "./scss/custom.scss"
import TemplateHeader from "./template-header";
import LeftMenu from "./left-menu";
import {Outlet} from "react-router";


const CustomerBootstrapTemplate = props =>{

    return(
        <>
            <div className="dashboard-inner bgs-blue-l account-inner">
                <TemplateHeader/>
                <div className="dashboard-main d-f">
                    <LeftMenu/>
                        <Outlet />
                </div>
            </div>
        </>
    )
}

export default CustomerBootstrapTemplate