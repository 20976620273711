//import "./auth-old.scss"
import {Link, useParams} from "react-router-dom";
import {sendResetPasswordTokenAPI} from "../../api";
import React, {useState} from "react";
import {toast} from "react-toastify";

const EmailForPasswordReset = props =>{
    const {  language='en',   } = useParams();
    const formSubmit = event =>{
        event.preventDefault();

        const form = event.currentTarget;
        const login = form.elements["email"].value;
        sendResetPasswordTokenAPI({login,language})
            .then(({error})=>{
                if(error){
                    toast.error(error)
                }else{
                    toast.success('Password was sent to email')
                }
            }).catch(err=>{
                toast.error(err.message);
            })
    }
    return(
        <div className="enter-inner reset-inner">
            <div className="logo">
                <a href="">
                    <img className="mw-100" src="/assets/images/logo-black.svg" alt="logo"/>
                </a>
            </div>
            <div className="enter-content">
                <div className="forma">
                    <div className="title">reset your password</div>
                    <form action="/" onSubmit={formSubmit}>
                        <div className="inputblock mb-3">
                            <label htmlFor="name">Your e-mail</label>
                            <input className="form-control" placeholder="name@coronium.io" name="email" id="email" type="email"
                                   required/>
                        </div>
                        <div className="order mt-4">
                            <button className="send cl-btn" type="submit">
                                Reset Password
                            </button>
                        </div>
                    </form>
                    <div className="link">
                        Remembered your password? <Link to={`/${language||'en'}/sign-in`}>Log in</Link>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default EmailForPasswordReset;