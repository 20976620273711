import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {useAuthUser} from "../../utils/AuthUser";
import {checkCredentials, verifyReCaptchaAPI} from "../../api";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import "./auth.scss"
import LeftSideWithDescription from "./left-side-with-description";
import ReCAPTCHA from "react-google-recaptcha";

const SignIn = () => {
    const { setLogged,  returnUrl } = useAuthUser();
    const [error, setError] = useState();
    const [step, setStep] = useState(1);
    const [captcha, setCaptcha] = useState(false);
    const form = useRef();
    const {  language='en',   } = useParams();
    const { t } = useTranslation('auth')
    const redirect = returnUrl&&returnUrl.length>2?returnUrl:'/';
    const navigate = useNavigate();
    const recaptchaRef = React.useRef();
    useEffect(()=>{
        setStep(1);
        setError(undefined);
    },[])
    // Create an event handler so you can call the verification on button click event or form submit

    const onSubmit = async event =>{
        event.preventDefault();
        const login = form.current.elements["login"].value;
        const password = form.current.elements["password"].value;
        const code = form.current.elements["code"].value;
        if(!captcha){
            try{
                const token = await recaptchaRef.current.executeAsync();
                let r = await verifyReCaptchaAPI({'g-recaptcha-response':token});
                if(!r.success){
                    return ;
                }
                setCaptcha(true);
            }catch (err){
                console.log(err.message);
                return
            }
        }


        if(step===2){
            try{
                const {error:err} = await checkCredentials(login,password, code);
                if(err){
                    setError(err);
                }else{
                    return form.current.submit();
                }
            }catch (err){
                setError(err.message);
            }
            return
        }
        setError(undefined);
        try{
          /* const check = await checkCredentials(login,password);
           const status = check.status;
           const d = await check.json();*/
            const d = await checkCredentials(login,password);
           if(d.status===404){
               return setError('Account not found!');
           }
            if(d.error){
                if(d.isActive===false){
                    return setError('Your account deactivated by admin!');
                }else {
                     setError(d.error)
                }
                if(d.isVerified==='false'||d.error==='Email not verified'){
                    navigate(`/${language}/confirm-email/${login}`);
                }

            }else{
                setError(undefined);
                if(d.use2fa){
                    setStep(2);
                }else{
                    return form.current.submit();
                }


               // setLogged(true);
            }
        }catch (err){
            setError(err.message)
        }
    }
    function onChange(value) {
       // console.log("Captcha value:", value);
    }
    return (
        <>
            <div className="enter-inner log-in-inner">

                <LeftSideWithDescription t={t}/>

                <div className="enter-main">
                    <div className="forma">
                        <div className="title">{t('Login_to_dashboard')}</div>
                        <form ref={form} onSubmit={onSubmit}  action='/signin' method='POST'>
                            <input type={'hidden'} name={'redirect'} value={redirect}/>
                            <div className={`inputblock mb-3 ${step===2&&'d-none'}`}>
                                <label htmlFor="name">{t("Your_email")}</label>
                                <input className="form-control" placeholder="name@coronium.io" name="login" id="login" type="email"
                                       required/>
                            </div>
                            <div className={`inputblock mb-3 ${step===2&&'d-none'}`}>
                                <label htmlFor="name">{t('Password')}</label>
                                <input className="form-control" placeholder={t('Password')}  id="password" name="password"
                                       type="password" required/>
                            </div>
                            <div className={`inputblock ${step===1&&'d-none'}`}>
                                <label htmlFor="password">Code</label>
                                <input className="form-control" type="text" name="code" id="code" placeholder="Google auth code"/>
                            </div>
                            {error&&<p className='text-danger'>{error}</p>}
                            <div className="order mt-4 mb-4">
                                <button className="send cl-btn" type="submit">
                                    {t('Login')}
                                </button>
                            </div>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY}
                                onChange={onChange}
                                badge={'inline'}
                                ref={recaptchaRef}
                                size="invisible"
                            />
                        </form>
                        <div className="link">
                            {t("Dont_have_an_account")}? <Link to={`/${language||'en'}/sign-up`} className='color-blue-n'>{t('Signup')}</Link>
                        </div>
                        <div className="link">
                            <Link to={`/${language||'en'}/reset-password`}>{t('Forgot_password')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default SignIn;

