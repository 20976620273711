import {Route, useLocation, useParams} from "react-router-dom";
import ResetPasswordPage from "../pages/reset-password";
import SignInPage from "../pages/sign-in";
import SignUpPage from "../pages/sign-up";
import NewPasswordPage from "../pages/new-password-form";
import {Routes} from "react-router";
import {useAuthUser} from "../utils/AuthUser";
import {useEffect} from "react";
import ConfirmEmailPage from "../pages/confirm-email";
import EmailVerifiedPage from "../pages/email-verified";

const UnauthorizedRoutes = props =>{
   // const {  language,   } = useParams();
    const location = useLocation();
    useEffect(() => {
        if (location.pathname.includes('sign-in')) {
            const [,language] = location.pathname.split('/')
            let path = language && language.length === 2 ? `/${language}/sign-in` : `/en/sign-in`;
            if(!location.pathname.includes(path)){
              //  console.log({path,pathname:location.pathname, language})
                window.location = path
            }

        }

    }, [location.pathname]);
    return(
        <>
            <Routes>
                <Route path='/:language/sign-in' element={<SignInPage/>}/>
                <Route path='/:language/sign-up' element={<SignUpPage/>} />
                <Route path='/:language/confirm-email/:email' element={<ConfirmEmailPage/>} />
                <Route path='/:language/email-verified/:email' element={<EmailVerifiedPage/>} />
                <Route exact path='/:language/reset-password' element={<ResetPasswordPage/>} />
                <Route exact path='/:language/reset-password/:token' element={<NewPasswordPage/>} />
                <Route
                    path="*"
                    element={<SignInPage/>}
                />
            </Routes>
        </>
    )
}
export default UnauthorizedRoutes;