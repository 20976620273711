import React, { useState, useContext, useEffect } from 'react'
import {ping} from "../api";
const AuthUserContext = React.createContext()

export const AuthUserProvider = ({ children, store }) => {
  const [logged, setLogged] = useState();
  const [returnUrl, setReturnUrl] = useState('/')

  const checkAuth = async ()=>{
    try{
      //console.log('ping')
      const {error} = await ping();
      if(error){
        setLogged(false);
        return Promise.reject()
      }else{
        setLogged(true);
        return Promise.resolve();
      }
    }catch (err){
      setLogged(false);
      return Promise.reject();
    }
  }
  useEffect(()=>{
    checkAuth().catch(e=>{})
  },[])

  return <AuthUserContext.Provider value={{ logged, setLogged, returnUrl, setReturnUrl, checkAuth }}>{children}</AuthUserContext.Provider>
}

export const useAuthUser = () => {
  return useContext(AuthUserContext)
}
